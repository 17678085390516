@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.list-unstyled {
  list-style-type: none; /* Removes bullet points */
  padding-left: 0; /* Removes left padding */
}
.font{
  font-family: "Rubik", sans-serif;
}
.transition:hover{
  transition: 800ms;
}
.nav-tabs .nav-link {
  color: black; /* Set link color to black */
  border: none; /* Remove border */
}
.table-border-top {
  border-top: 2px solid #333; /* Change the color to your preferred darker shade */
  border-collapse: collapse; /* This ensures that borders between cells are collapsed into a single border line */
  border-spacing: 0; /* Set spacing between cells to zero */
}

.tabs p{
  font-family: "Rubik", sans-serif;
  cursor: pointer;
  font-weight: 600;
}

.section-bg {
  background-image: url('./images/bg-section.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  /* padding: 60px 0;  */
}

/* @media (max-width: 767.98px) {
  .section-bg {
    background-attachment: scroll;
    padding: 40px 0; 
  }
} */



.header-bg{
  background-image: url('./images/header-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; 
  
}


.get-in-touch {
  color: #fff;
  font-weight: 300;
  border: 2px solid #fff;
  border-radius: 18px;
padding-top: 10px;
padding-bottom: 10px;
padding-left: 20px;
padding-right: 20px;
  display: inline-block;
  cursor: pointer;
}
/* .get-in-touch :hover{
  background-color:   #fff;
  color: #333;
} */
/* .header-btn:hover{
background-color: #ffffff8e;
border: 1px solid red;
} */


.nav-link-price.active {
  background-color: #343a40; /* Dark background color */
  color: #f8f9fa !important; /* Light text color */
  transition: background-color 0.3s ease, color 0.3s ease;
}
.btn:hover {
  background-color: rgb(252, 252, 252) !important;
  color: rgb(5, 5, 5) !important;
}
.schedule-btn:hover{
  background-color:black !important;
  color:  rgb(252, 252, 252) !important;
}

.nav-link.active {
  border-bottom: 2px solid white;
}

.map-bg {
  position: relative;
}

.map-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('images/map.png');
  background-size: cover;
  background-position: center;
  opacity: 0.4; /* Adjust this value to change the opacity */
  z-index: -1;
}

@media screen and (max-width: 575px) {
 body #img-margin-top{
  margin-top: 10px !important;
 }
}
